import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { navigate } from "@reach/router";
import React, { useEffect, useRef, useState } from "react";
import { Field, reduxForm } from "redux-form";
import CreditCardField from "../creditCardField";
import {
  renderDateField,
  renderNumberField,
  renderRadioGroup,
  renderSelectField,
  renderTextField,
} from "../renderInputsForm";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 100,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 32
  },
  subtitle: {
    fontWeight: 100,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 20,
    marginTop: 25
  },
  button: {
    marginRight: theme.spacing(4),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    '& button': {
      marginTop: 8,
      marginBottom: 8
    }
  },
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
    padding: 0,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: 0.15008
  },
  small: {
    fontWeight: 300,
    letterSpacing: 0.09,
    lineHeight: '19.2px',
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.87)',
  }
}));

const StyledStepLabel = withStyles({
  label: {
    fontWeight: "100 !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: 32,
  },
})(StepLabel);

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "name",
    "lastname",
    "documentNumber",
    "birthday",
    "gender",
    "email",
    "phone",
    "street",
    "streetNumber",
    "department",
    "location",
    "cardType",
    "cardNumber",
    "cardExpirationDate",
    "patentFormat",
    "patent",
    "vehicleColor",
    "vehicleChassisNumber",
    "vehicleEngineNumber"
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Requerido";
    }
  });

  /* if (
      values.peso &&
      (Number(values.peso) < 100 || Number(values.peso) > 350)
  ) {
      errors.peso = "Valor fuera de rango (min: 100, max: 350)";
  }

  if (
      values.estatura &&
      (Number(values.estatura) < 0.6 || Number(values.estatura) > 2.2)
  ) {
      errors.estatura = "Valor fuera de rango (min: 0.60, max: 2.20)";
  } */
  return errors;
};

const emailValidation = value => (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) || value === '') ?
  'Debe ser un mail válido' : undefined

const LibraClientInfoForm = ({
  handleSubmit,
  submitting,
  valid,
  onDataReturn,
  goToNextPage,
  quotationScreenValues,
  initialize,
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [creditError, setCreditError] = useState(false);
  const [creditCardNumber, setCreditCardNumber] = useState(null);
  const [creditCardExpiryDate, setCreditCardExpiryDate] = useState(null);
  const [creditCardCVC, setCreditCardCVC] = useState(null);

  useEffect(() => {
    if (
      quotationScreenValues.clientInfo &&
      quotationScreenValues.isFullfilledClientInfoForm
    ) {
      const _document = quotationScreenValues.clientInfo;
      /* setConditionalInputs(
          _document &&
          Object.keys(_document)
              .filter((k) => k.includes("_"))
              .map((k) => k.split("_")[0])
      ); */
      initialize({
        ..._document,
      });
    } else {
      initialize({
        email: quotationScreenValues.lead
      });
    }
  }, [quotationScreenValues]);


  const handleScroll = () => {
    const allTitles = Array.from(document.querySelectorAll('.client__vinculation-title'));
    const nextTitle = allTitles[activeStep];
    window.scrollTo({
      top: nextTitle.offsetTop - nextTitle.offsetHeight,
      behavior: 'smooth'
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmits = async (values) => {
    console.log(values);
    const formattedValues = {
      ...values,
      creditCardNumber,
      creditCardExpiryDate,
      creditCardCVC
    };
    console.log(formattedValues);
    await onDataReturn(formattedValues);
    goToNextPage();
  };

  const handleCreditCardChange = (e, v) => {
    switch (e.target.name) {
      case 'cardNumber':
        setCreditCardNumber(e.target.value);
        break;
      case 'expiryDate':
        setCreditCardExpiryDate(e.target.value);
        break;
      case 'cvc':
        setCreditCardCVC(e.target.value);
        break;
    };
  };

  const goBack = () => {
    navigate('proposal');
  }

  const genderOptions = [
    { value: "Masculino", label: "Masculino" },
    { value: "Femenino", label: "Femenino" },
    { value: "Sin especificar", label: "Sin especificar" },
  ];


  const patentOptions = [
    { value: "1234AB", label: "1234AB" },
    { value: "123ABC", label: "123ABC" },
    { value: "123ABCD", label: "123ABCD" },
    { value: "12A3456", label: "12A3456" },
    { value: "A/D", label: "A/D" },
    { value: "A12345", label: "A12345" },
    { value: "A123BCD", label: "A123BCD" },
    { value: "AAA1234", label: "AAA1234" },
    { value: "AAA1A11", label: "AAA1A11" },
    { value: "AB1C23D", label: "AB1C23D" },
    { value: "ABC12D", label: "ABC12D" },
    { value: "ABCD12", label: "ABCD12" },
  ];

  const cardTypes = [
    { value: "AMEX", label: "AMEX" },
    { value: "CABAL", label: "CABAL" },
    { value: "MASTERCARD", label: "MASTERCARD" },
    { value: "NARANJA", label: "NARANJA" },
    { value: "VISA", label: "VISA" }
  ];

  console.log('CRC Metadata', {
    creditCardNumber,
    creditCardExpiryDate,
    creditCardCVC
  });

  return (
    <form onSubmit={handleSubmit(handleSubmits)}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StyledStepLabel className="client__vinculation-title">
            Datos generales
          </StyledStepLabel>
          <StepContent TransitionProps={{
            onExited: handleScroll,
            timeout: {
              enter: 250,
              exit: 250
            }
          }}>
            <Grid container>
              <Grid item xs={12} style={{ padding: 8 }}>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="name"
                    label="Nombres"
                    component={renderTextField}
                    custom={{ tabIndex: 1 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="lastname"
                    label="Apellidos"
                    component={renderTextField}
                    custom={{ tabIndex: 2 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="documentNumber"
                    label="Numero de documento"
                    component={renderTextField}
                    custom={{ tabIndex: 3 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="birthday"
                    label="Fecha de nacimiento"
                    component={renderDateField}
                    custom={{ tabIndex: 4 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="gender"
                    label="Sexo"
                    component={renderSelectField}
                    custom={{ tabIndex: 5 }}
                  >
                    {genderOptions.map(
                      (j, i) => (
                        <MenuItem
                          key={`${j.value}__${i}`}
                          value={j.value}
                        >
                          {j.label}
                        </MenuItem>
                      )
                    )}
                  </Field>
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="email"
                    label="Correo"
                    component={renderTextField}
                    custom={{ tabIndex: 6 }}
                    validate={[emailValidation]}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="phone"
                    label="Teléfono"
                    component={renderTextField}
                    custom={{ tabIndex: 7 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  onClick={goBack}
                  className={classes.button}
                  variant="outlined"
                >
                  ATRÁS
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={!valid}
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step>
          <StyledStepLabel className="client__vinculation-title">Ubicacion</StyledStepLabel>
          <StepContent TransitionProps={{
            onExited: handleScroll,
            timeout: {
              enter: 250,
              exit: 250
            }
          }}>
            <Grid container>
              <Grid item xs={12} style={{ padding: 8 }}>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="street"
                    label="Calle"
                    component={renderTextField}
                    custom={{ tabIndex: 8 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="streetNumber"
                    label="Numero"
                    type="number"
                    component={renderNumberField}
                    custom={{ tabIndex: 9 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="department"
                    label="Piso y departamento"
                    component={renderTextField}
                    custom={{ tabIndex: 10 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="location"
                    label="Localidad"
                    component={renderTextField}
                    custom={{ tabIndex: 11 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  onClick={handleBack}
                  className={classes.button}
                  variant="outlined"
                >
                  ATRÁS
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={!valid}
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step>
          <StyledStepLabel className="client__vinculation-title">
            Datos de tarjeta de credito
          </StyledStepLabel>
          <StepContent TransitionProps={{
            onExited: handleScroll,
            timeout: {
              enter: 250,
              exit: 250
            }
          }}>
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xs={12} style={{ padding: 8 }}>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="cardType"
                    label="Tipo de tarjeta"
                    component={renderSelectField}
                    custom={{ tabIndex: 12 }}
                  >
                    {cardTypes.map(
                      (j, i) => (
                        <MenuItem
                          key={`${j.value}__${i}`}
                          value={j.value}
                        >
                          {j.label}
                        </MenuItem>
                      )
                    )}
                  </Field>
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <CreditCardField
                    creditError={setCreditError}
                    creditCardNumber={creditCardNumber}
                    creditCardExpiryDate={creditCardExpiryDate}
                    creditCardCVC={creditCardCVC}
                    handleChange={handleCreditCardChange} />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  onClick={handleBack}
                  className={classes.button}
                  variant="outlined"
                >
                  ATRÁS
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={!valid || creditError !== undefined}
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step>
          <StyledStepLabel className="client__vinculation-title">Datos del vehiculo</StyledStepLabel>
          <StepContent TransitionProps={{
            onExited: handleScroll,
            timeout: {
              enter: 250,
              exit: 250
            }
          }}>
            <Grid container>
              <Grid item xs={12} style={{ padding: 8 }}>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="patentFormat"
                    label="Formato de patente"
                    component={renderSelectField}
                    custom={{ tabIndex: 14 }}
                  >
                    {patentOptions.map(
                      (j, i) => (
                        <MenuItem
                          key={`${j.value}__${i}`}
                          value={j.value}
                        >
                          {j.label}
                        </MenuItem>
                      )
                    )}
                  </Field>
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="patent"
                    label="Patente"
                    component={renderTextField}
                    custom={{ tabIndex: 14 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="vehicleColor"
                    label="Color"
                    component={renderTextField}
                    custom={{ tabIndex: 15 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="vehicleChassisNumber"
                    label="Numero de Chasis"
                    component={renderTextField}
                    custom={{ tabIndex: 16 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="vehicleEngineNumber"
                    label="Numero de motor"
                    component={renderTextField}
                    custom={{ tabIndex: 17 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  onClick={handleBack}
                  className={classes.button}
                  variant="outlined"
                >
                  ATRÁS
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={submitting || !valid}
                  type="submit"
                >
                  Guardar
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </form>
  );
};

export default reduxForm({ form: "LibraClientInfoForm", validate })(
  LibraClientInfoForm
);
