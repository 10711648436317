import React from "react";
import {
    Card,
    CardContent,
    CardHeader,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
const useStyle = makeStyles((theme) => ({
    card: {
        borderRadius: 20,
        backgroundColor: "#EAF6FF",
    },
    cardContent: {
        paddingRight: 26,
        paddingLeft: 27,
        "&:last-child": {
            paddingBottom: 40,
        },
    },
    cardHeader: {
        textAlign: "center",
        color: "#FFF",
        paddingTop: 31,
        paddingBottom: 30,
        fontSize: 36,
        lineHeight: "24px",
        letterSpacing: 0.15,
        fontWeight: 300,
        backgroundColor: "#2373A3",
        [theme.breakpoints.down("xs")]: {
            lineHeight: "40px",
            fontSize: 24,
        },
    },
    cardTitle: {
        fontWeight: 500,
    },
    table: {
        color: "#02183A",
        "& .MuiTableRow-root:last-child .MuiTableCell-root": {
            borderBottom: "none",
        },
        "& .MuiTableCell-root, .MuiTableRow-root:first-child .MuiTableCell-root":
            {
                borderBottom: "1px solid #CADDE9",
            },
        "& .MuiTableRow-head .MuiTableCell-head": {
            color: "#2373A3",
            fontSize: 20,
            lineHeight: "24px",
            fontWeight: 500,
            letterSpacing: 0.15,
            borderBottom: "none !important",
        },
    },
}));

const CardSummary = ({ plan, quotationScreenValues, riskType }) => {
    const classes = useStyle();
    const name = plan && plan.name ? plan.name : ""
    const price = plan && plan.price ? plan.price : 1
    
    console.log(quotationScreenValues);
    return (
        <Card
            elevation={0}
            classes={{
                root: classes.card,
            }}
        >
            <CardHeader
                classes={{
                    root: classes.cardHeader,
                }}
                title={
                    <Typography variant={"inherit"}>
                        Plan{" "}
                        <span className={classes.cardTitle}>{name}</span>
                    </Typography>
                }
            />

            <CardContent classes={{ root: classes.cardContent }}>
                <Table classes={{ root: classes.table }}>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Importe
                            </TableCell>
                            <TableCell align="right">
                                <strong>{`$${(price/ 12).toFixed(
                                    2
                                )}/mes`}</strong>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                {riskType.toLowerCase() !== 'autos' && <Table classes={{ root: classes.table }}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>Asegurados</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {`${quotationScreenValues?.clientInfo?.name} ${
                                    quotationScreenValues?.clientInfo?.surname || ""
                                }`}
                            </TableCell>
                        </TableRow>
                        {quotationScreenValues &&
                            quotationScreenValues.items &&
                            quotationScreenValues.items.map(
                                (personInsured, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {personInsured.groupName ===
                                            "conyuge"
                                                ? personInsured.nameconyuge
                                                : personInsured.namehijo}
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>
                                                {personInsured.groupName} -{" "}
                                                {personInsured.groupName ===
                                                "conyuge"
                                                    ? (personInsured.spouseAge || personInsured.age)
                                                    : personInsured.age}{" "}
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                    </TableBody>
                </Table>}
            </CardContent>
        </Card>
    );
};

export default CardSummary;
