import React from "react";
import clsx from "clsx";
import { makeStyles, StepConnector } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.variants.variant1,
    zIndex: 1,
    color: "#fff",
    width: 24.94,
    height: 24.94,
    [theme.breakpoints.down("sm")]: {
      width: 10,
      height: 10,
    },
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: theme.palette.secondary.light,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "#C4C4C4",
  },
}));

export const DotConnector = withStyles((theme) => ({
  alternativeLabel: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    left: "-50%",
    right: "50%",
  },
  active: {
    "& $line": {
      backgroundColor: theme.palette.variants.variant1,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: theme.palette.variants.variant1,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.variants.variant1,
    borderRadius: 1,
  },
}))(StepConnector);

export const DotStepIcon = (props) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    ></div>
  );
};
